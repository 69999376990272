// extracted by mini-css-extract-plugin
export var categoryDescription = "CategoryResources__categoryDescription__kdLbs";
export var categoryItemContainer = "CategoryResources__categoryItemContainer__DgSgJ";
export var categoryTitle = "CategoryResources__categoryTitle__ZKdCT";
export var column = "CategoryResources__column__F4Jbd";
export var downloadButtonContainer = "CategoryResources__downloadButtonContainer__Fv57C";
export var itemDescription = "CategoryResources__itemDescription__WRBJ1";
export var itemDetailContainer = "CategoryResources__itemDetailContainer__XP5s0";
export var itemHeading = "CategoryResources__itemHeading__U8vT7";
export var itemImageContainer = "CategoryResources__itemImageContainer__AxsI4";
export var paginationContainer = "CategoryResources__paginationContainer__huTcg";
export var resourceImage = "CategoryResources__resourceImage__OoWPv";
export var row = "CategoryResources__row__y8br3";