// extracted by mini-css-extract-plugin
export var action = "ResourcesLanding__action__t35gv";
export var actionControl = "ResourcesLanding__actionControl___i6BL";
export var categoryField = "ResourcesLanding__categoryField__D4r6o";
export var column = "ResourcesLanding__column__sx8hg";
export var container = "ResourcesLanding__container__uWcE8";
export var contentContainer = "ResourcesLanding__contentContainer__mm1mZ";
export var darkAdWrapper = "ResourcesLanding__darkAdWrapper__M5X_W";
export var filters = "ResourcesLanding__filters__Y4mpB";
export var grid = "ResourcesLanding__grid__EvmbI";
export var logo = "ResourcesLanding__logo__sYGz7";
export var notFoundContainer = "ResourcesLanding__notFoundContainer__DoA0I";
export var reason = "ResourcesLanding__reason__Sg92i";
export var row = "ResourcesLanding__row__fHrhK";
export var searchButton = "ResourcesLanding__searchButton__U1X7x";
export var searchField = "ResourcesLanding__searchField__oukLT";
export var searchFieldWrapper = "ResourcesLanding__searchFieldWrapper__jZQhh";
export var searchIcon = "ResourcesLanding__searchIcon__IVsgn";
export var sideColumn = "ResourcesLanding__sideColumn__FHGHj";