// extracted by mini-css-extract-plugin
export var column = "Select__column__JbOGg";
export var error = "Select__error___N7X7";
export var icon = "Select__icon__ODRFF";
export var marginLeft = "Select__marginLeft__YpZxG";
export var marginRight = "Select__marginRight__wlfaG";
export var placeholder = "Select__placeholder___LRbr";
export var row = "Select__row__g7uvb";
export var select = "Select__select__Xt23G";
export var sizeLg = "Select__sizeLg__eeu_q";
export var sizeMd = "Select__sizeMd__yNSVm";
export var sizeSm = "Select__sizeSm__Pe3oH";
export var sizeXl = "Select__sizeXl__ZY3Kq";
export var sizeXs = "Select__sizeXs__oNe7f";
export var sizeXxl = "Select__sizeXxl__Y6GhA";
export var sizeXxs = "Select__sizeXxs__CCWXg";
export var sizeXxxl = "Select__sizeXxxl__Lxn0f";
export var sizeXxxs = "Select__sizeXxxs__LCRdv";
export var sizeXxxxl = "Select__sizeXxxxl___CHBI";
export var sizeXxxxxl = "Select__sizeXxxxxl__Oj5m5";
export var value = "Select__value__YR0uW";
export var wrapper = "Select__wrapper__zO1V9";